import { type StaticEnvironmentModel } from '@big-direkt/utils/environment';
import { jsonLd } from './json-ld';
import { metaTags } from './metatags';

export const environment: StaticEnvironmentModel = {
    coBrowsingUrl: '',
    faqApiPath: 'jsonapi/node/faq_wrapper',
    formApiPath: 'api/form',
    jsonapiPath: '/jsonapi',
    jsonLd,
    metaTags,
    privateContentPath: '/de/meinebig',
    placeholdersApiPath: 'jsonapi/taxonomy_term/placeholders',
    production: true,
    publicFormsHostUrl: '/public-forms',
    restapiPath: '/api',
    sentryDsn: 'https://10198052238645d6b328a84c6243fbe9@sentry.operations.big-osp.de/7',
    sentryEnvironment: 'big-embedded-prod',
    sentryRelease: 'big-embedded-prod',
    sentryTracesSampleRate: 0.1,
    sentryTracingOrigins: ['app.big-direkt.de', 'app.stage.big-direkt.de', 'app.test.big-direkt.de'],
    serviceToolsPath: '',
    sessionTokenApiPath: 'session/token',
    errorNodeIds: {
        notFound: {
            de: '38a41731-b53b-4885-b69c-9c9becd4a16d',
        },
    },
    servicePhoneNumber: {
        number: '+4980054565456',
        title: '0800 545 654 56',
    },
    errorPhoneNumber: {
        number: '+492315557320133',
        title: '0231 555 73 20133',
    },
};
