import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

@Component({
    standalone: true,
    imports: [
        RouterOutlet,
        LoadingBarModule,
    ],
    selector: 'big-direkt-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
}
