import { type Route } from '@angular/router';
import { ContentResolverService } from '@big-direkt/content';
import { ErrorComponent } from './error/error.component';

export const appRoutes: Route[] = [
    {
        path: '',
        component: ErrorComponent,
    },
    {
        path: '**',
        loadComponent: async () => (await import('./content/content.component')).ContentComponent,
        resolve: { content: ContentResolverService },
        data: { showPageTitle: false },
    },
];
