import { type HttpEvent, type HttpHandler, type HttpInterceptor, type HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MobileAppRepository } from '@big-direkt/state/mobile-app';
import { type Observable } from 'rxjs';

@Injectable()
export class MobileAppVersionHeaderInterceptor implements HttpInterceptor {
    private readonly mobileAppRepository = inject(MobileAppRepository);

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.mobileAppRepository.isEmbeddedInMobileApp()) {
            return next.handle(req);
        }

        // eslint-disable-next-line @typescript-eslint/naming-convention
        return next.handle(req.clone({ setHeaders: { 'X-App-Version': this.mobileAppRepository.version() } }));
    }
}
