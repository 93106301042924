import { Component } from '@angular/core';
import { ErrorHandlingAlertComponent } from '@big-direkt/error-handling';
import { UiAlertComponent } from '@big-direkt/ui/alert';


@Component({
    selector: 'big-error',
    templateUrl: './error.component.html',
    standalone: true,
    imports: [
        UiAlertComponent,
        ErrorHandlingAlertComponent,
    ],
})
export class ErrorComponent {

}
